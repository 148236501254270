<template>
  <b-card no-body v-if="dashboard">
    <b-card-header class="pb-0">
      <b-card-title>Serviços</b-card-title>
      <span class="text-muted">Todos</span>
    </b-card-header>
    <hr class="mx-2 mb-0" />
    <b-card-body class="pt-0">
      <b-row>
        <b-col md="5" class="m-0 p-0">
          <!-- apex chart -->
          <vue-apex-charts
            type="pie"
            height="188"
            class="my-1"
            :options="customersPie.chartOptions"
            :series="customersPie.series"
          />
        </b-col>
        <b-col md="7" align-self="center">
          <ul class="list-unstyled px-1">
            <li class="">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-primary"
                class="btn-icon rounded-circle"
                @click="abrirModal(dashboard.producao)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
              <span
                ><strong>Produção</strong> <span class="float-right" style="margin-top: 7px;">{{
                  dashboard.totalProducao
                }}</span></span
              >
            </li>
            <li>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-warning"
                class="btn-icon rounded-circle"
                @click="abrirModal(dashboard.prova)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
              <span
                ><strong>Prova</strong> <span class="float-right" style="margin-top: 7px;">
                  {{ dashboard.totalProva }}</span
                ></span
              >
            </li>
            <li>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-danger"
                class="btn-icon rounded-circle"
                @click="abrirModal(dashboard.pendente)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
              <span
                ><strong>Pendente</strong> <span class="float-right" style="margin-top: 8px;">
                  {{ dashboard.totalPendente }}</span
                ></span
              >
            </li>
            <li>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-info"
                class="btn-icon rounded-circle"
                @click="abrirModal(dashboard.saiu_entrega)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
              <span
                ><strong>Saiu p/ Entrega</strong> <span class="float-right" style="margin-top: 8px;">
                  {{ dashboard.totalSaiuEntrega }}</span
                ></span
              >
            </li>
          </ul>
        </b-col>
        
      </b-row>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        block
        variant="primary"
        @click="abrirModal(dashboard.pedido)"
      >
        Meus Pedidos ( {{dashboard.totalPedido}} )
      </b-button>
    </b-card-body>
    <ModalServicos :dados="dados"/>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BBadge,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BButton,
  BModal,
  VBModal,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import Ripple from "vue-ripple-directive";
import { mapState } from "vuex";
import ModalServicos from "./ModalServicos.vue";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BBadge,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    VueApexCharts,
    BButton,
    BModal,
    VBModal,

    ModalServicos,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      dados: [],
      customersPie: {
        series: [690, 258, 149, 222],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: ["Produção", "Prova", "Pendentes", "Saiu p/ Entrega"],
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          stroke: {
            width: 0.5,
          },
          colors: [
            $themeColors.primary,
            $themeColors.warning,
            $themeColors.danger,
            $themeColors.info,
          ],
        },
      },
    };
  },
  mounted() {
    this.valorChart();
  },
  computed: {
    ...mapState({
      dashboard: (state) => state.dashboard.dashboard.servicos,
    }),
  },
  methods: {
    valorChart() {
      return setTimeout(
        () =>
          (this.customersPie.series = [
            this.dashboard.totalProducao,
            this.dashboard.totalProva,
            this.dashboard.totalPendente,
            this.dashboard.totalSaiuEntrega,
          ]),
        1000
      );
    },

    abrirModal(conteudo) {
      this.dados = conteudo
      this.$bvModal.show('modalServicos')
    }
  },
};
</script>
