import { render, staticRenderFns } from "./CardFaturaAtrasada.vue?vue&type=template&id=1e346350&scoped=true&"
import script from "./CardFaturaAtrasada.vue?vue&type=script&lang=js&"
export * from "./CardFaturaAtrasada.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e346350",
  null
  
)

export default component.exports