<template>
  <b-card text-variant="center" class="card card-congratulations">
    <!-- images -->
    <b-img class="congratulations-img-left" />
    <b-img class="congratulations-img-right" />
    <!--/ images -->

    <b-avatar variant="primary" size="78" class="shadow">
      <feather-icon size="28" icon="AwardIcon" />
    </b-avatar>
    <h1 class="mb-1 text-white">Bem vindo ao OdontoDocs</h1>
    <b-card-text class="m-auto w-75">
      Controle total dos Pedidos ao Laboratório e Compartilhamento de arquivos! <strong></strong>
    </b-card-text>
  </b-card>
</template>

<script>
import { BCard, BImg, BAvatar, BCardText } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BAvatar,
    BImg,
    BCardText,
  },
};
</script>
